<template>
  <div class="timeslot-selector">
    <ebsn-meta
      class="text-center"
      :target="category"
      path="category_info.TITLE"
      :default-value="category.name"
      tag="h1"
    ></ebsn-meta>
    <ebsn-meta
      :target="category"
      path="category_info.DESCRIPTION"
      class="text-center text-body-2 mt-2"
    ></ebsn-meta>
    <category-block
      :target="category"
      position="position1"
      class="category-block category-block-1"
      :container="false"
    />
    <h4 class="mt-5 mb-3">
      {{ $t("timeslots.dayLabel") }}
    </h4>
    <v-tabs
      hide-slider
      v-model="tab"
      center-active
      show-arrows
      :height="70"
      class="days-tabs my-8"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab
        v-for="timeslotDay in days"
        :key="timeslotDay.dateIso"
        class="days-tab rounded-lg mr-3"
        @click="selectTimeslotDay(timeslotDay)"
      >
        <div
          v-if="dayHasPromo(timeslotDay)"
          class="timeslot-promo secondary rounded font-weight-bold white--text"
        >
          <small>{{ $t("timeslots.promo") }}</small>
        </div>
        <div
          class="time-div d-flex flex-column align-center justify-space-around"
        >
          <template v-if="addressId">
            <span class="today text-body-1 font-weight-bold">{{
              $t(`eugenio.timeslot.days.${timeslotDay.weekDay}`)
            }}</span>
          </template>
          <template v-else-if="$dayjs().isSame(timeslotDay.dateIso, 'day')">
            <span class="today text-body-1 font-weight-bold">
              {{ $t("timeslots.today") }}
            </span>
          </template>
          <template v-else>
            <span class="day-month text-body-1 font-weight-bold">
              {{ $dayjs(timeslotDay.dateIso).format("DD MMMM") }}
            </span>
            <span class="day-week text-body-2">
              {{ $dayjs(timeslotDay.dateIso).format("dddd") }}
            </span>
          </template>
        </div>
      </v-tab>
    </v-tabs>

    <h4 class="mt-5 mt-md-8 mb-2 mb-md-3">
      {{ $t("timeslots.timeLabel") }}
    </h4>

    <v-tabs-items v-model="tab">
      <v-tab-item
        class="timeslot-container"
        v-for="timeslotDay in days"
        :key="timeslotDay.dateIso"
      >
        <v-row
          v-if="
            timeslotDay &&
              timeslotDay.timeslots &&
              timeslotDay.timeslots.length > 0
          "
        >
          <v-col
            cols="6"
            sm="4"
            :md="isNotPopup ? 3 : 4"
            :lg="isNotPopup ? 2 : 4"
            v-for="timeslot in timeslotDay.timeslots"
            :key="timeslot.timeslotId"
            class="my-6 d-flex justify-center align-center"
            style="position:relative;"
          >
            <div
              class="timeslot-promo secondary rounded font-weight-bold white--text"
              v-if="
                timeslot.userGiftCertificates &&
                  timeslot.userGiftCertificates.length > 0
              "
            >
              {{ timeslot.userGiftCertificates[0].giftCertificate.name }}
            </div>
            <v-card
              outlined
              class="timeslot-card w-100 rounded-lg"
              :disabled="disabled"
              :class="[timeslot.status, { selected: timeslot.selected }]"
              v-on:click="selectTimeslot(timeslot)"
            >
              <v-card-title class="justify-center">
                {{ timeslot.beginTime }} - {{ timeslot.endTime }}
              </v-card-title>
              <v-card-subtitle class="text-center">
                {{ $t(`timeslots.availability.${timeslot.status}`) }}
              </v-card-subtitle>
            </v-card>
          </v-col>
        </v-row>
        <p v-else>
          <v-alert type="error" outlined>{{ $t("timeslots.noSlots") }}</v-alert>
        </p>
      </v-tab-item>
    </v-tabs-items>
    <category-block
      :target="category"
      position="position2"
      class="category-block category-block-2"
      :container="false"
    />
  </div>
</template>
<style lang="scss">
.timeslot-selector {
  @media #{map-get($display-breakpoints, 'xs-only')} {
    h1 {
      font-size: 2rem;
    }
  }
  padding: 30px;
  .days-tabs {
    .v-item-group {
      .v-slide-group__next,
      .v-slide-group__prev {
        height: 93px;
      }
      .v-slide-group__wrapper {
        contain: initial !important;
        height: 93px;
        padding: 12px 0;
        // overflow: visible !important;
      }
    }

    .days-tab {
      .timeslot-promo {
        position: absolute;
        top: -13px;
        padding: 2px;
        padding-bottom: 3px;
        font-size: 0.9rem;
      }
      min-width: 125px;
      border: 2px solid var(--v-grey-lighten1);
      .time-div {
        color: var(--v-default-base);
      }
      &.v-tab--active {
        border: 2px solid var(--v-primary-base);
        background-color: var(--v-primary-base);
        .time-div {
          color: var(--v-white-base);
        }
      }
    }
  }
  .timeslot-container {
    .timeslot-promo {
      position: absolute;
      top: 0px;
      padding: 2px;
      padding-bottom: 3px;
      font-size: 0.9rem;
      z-index: 7;
    }
  }
  .timeslot-card {
    @media #{map-get($display-breakpoints, 'xs-only')} {
      .v-card__title {
        font-size: 1rem;
        padding-top: 6px;
      }
      .v-card__subtitle {
        padding-bottom: 6px;
      }
    }
    &.empty {
      border-color: var(--v-primary-base);
    }
    &.intermediate {
      border-color: var(--v-accent-base);
    }
    &.full {
      border-color: var(--v-grey-lighten1);
      background-color: var(--v-grey-lighten2);
    }
  }
}
</style>
<script>
import DeliveryService from "~/service/deliveryService";
import CategoryService from "~/service/categoryService";

import { mapState } from "vuex";

import get from "lodash/get";
import recurrentOrderService from "@/commons/service/recurrentOrderService";

export default {
  name: "TimeslotSelector",
  props: {
    isNotPopup: { type: Boolean, default: false },
    addressId: { type: String, required: false },
    fetchedCategory: { type: Object, required: false }
  },
  data() {
    return {
      days: [],
      tab: 0,
      category: {},
      selectedTimeslotDay: {},
      disabled: false
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    title() {
      return get(
        this.category,
        "metaData.category_info.TITLE",
        this.category.name
      );
    },
    description() {
      return get(
        this.category,
        "metaData.category_info.DESCRIPTION",
        this.category.description
      );
    }
  },
  methods: {
    dayHasPromo(day) {
      if (day.timeslots && day.timeslots.length > 0) {
        for (const timeslot of day.timeslots) {
          if (
            timeslot.userGiftCertificates &&
            timeslot.userGiftCertificates.length > 0
          ) {
            return true;
          }
        }
      }
      return false;
    },
    selectTimeslotDay(day) {
      this.selectedTimeslotDay = day;
    },
    async selectTimeslot(timeslot) {
      if (this.addressId) {
        this.$emit("timeslotSelected", {
          timeslotId: timeslot.timeslotId,
          weekDay: this.selectedTimeslotDay.weekDay
        });
      } else {
        let _this = this;
        if (timeslot.active < 1) {
          return;
        }
        _this.disabled = true;
        let data = await _this.$store.dispatch("cart/setTimeslot", {
          dateIso: _this.selectedTimeslotDay.dateIso,
          timeslotId: timeslot.timeslotId
        });
        if (data) {
          this.$emit("submit", true);
        }
      }
    },
    async fetchTimeslots() {
      let _this = this;
      if (this.addressId) {
        let res = await recurrentOrderService.getTimeslots(this.addressId);
        if (res?.response.status === 0) {
          _this.days = res.data.days.filter(day => day.timeslots.length !== 0);
        }
        if (_this.days.length > 0) {
          _this.selectedTimeslotDay = this.days[0];
        }
      } else {
        _this.days = await DeliveryService.getTimeslotList(false);
        if (_this.days.length > 1 && _this.days[0].timeslots.length == 0) {
          _this.days.shift();
        }

        for (var i = 0; i < _this.days.length; i++) {
          if (_this.days[i].dateIso == _this.cart.timeslot.date) {
            _this.tab = i;
            this.selectedTimeslotDay = _this.days[i];
            break;
          }
        }
        if (!_this.selectedTimeslotDay.dateIso) {
          _this.selectTimeslotDay(_this.days[0]);
        }
      }

      return false;
    },
    async fetchCategory() {
      try {
        this.category = await CategoryService.getCategoryBySlug("timeslot");
      } catch (e) {
        console.log(e);
      }
    }
  },
  mounted() {
    this.disabled = false;
    this.fetchTimeslots();
    if (this.fetchedCategory) {
      this.category = this.fetchedCategory;
    } else {
      this.fetchCategory();
    }
  }
};
</script>
