<template>
  <div
    v-if="products.length > 0"
    :id="`product-list-slider-${config.categoryBlockId}`"
    class="product-list-slider"
    :style="style"
    v-intersect.once="handleView"
  >
    <ebsn-style
      :target="config"
      :path="`categoryblocktype_${name}.CSS_STYLE`"
      :styleWrapper="`#product-list-slider-${config.categoryBlockId}`"
    />

    <v-container fluid :class="{ 'pa-0': container === false }">
      <div
        v-if="showMore && !showCard"
        class="title-row d-flex flex-column flex-md-row justify-md-space-between align-start align-md-center mb-5"
      >
        <ebsn-meta
          :target="config"
          :path="`categoryblocktype_${name}.TITLE`"
          tag="h2"
          :style="titleColor ? 'color: ' + titleColor : ''"
        />
        <v-btn
          v-if="
            !$ebsn.meta(this.config, 'categoryblocktype_' + name + '.HIDE_LINK')
          "
          link
          text
          class="align-self-end align-self-md-center"
          :to="{
            name: 'Category',
            params: {
              pathMatch: $ebsn.meta(
                this.config,
                'categoryblocktype_' + name + '.CATEGORY.slug'
              )
            }
          }"
        >
          <span class="text-underline"
            >{{
              $ebsn.meta(
                this.config,
                "categoryblocktype_" + name + ".CATEGORY.BUTTON_TEXT",
                $t("categoryBlock.productListSlider.showAll")
              )
            }}
          </span>
          <v-icon class="ml-3">$arrowForward</v-icon>
        </v-btn>
      </div>
      <ebsn-meta
        :target="config"
        :path="`categoryblocktype_${name}.DESCRIPTION`"
        tag="div"
      />
      <div class="slider product-slider">
        <swiper :options="swiperOption" ref="swiperRef">
          <swiper-slide v-if="showCard" class="swiper-slide-card">
            <product-slider-card
              :img="
                $ebsn.meta(
                  config,
                  'categoryblocktype_' + name + '.CARD_IMAGE.location'
                )
              "
              :text="
                $ebsn.meta(config, 'categoryblocktype_' + name + '.CARD_TEXT')
              "
              :link="{
                name: 'Category',
                params: {
                  pathMatch: $ebsn.meta(
                    this.config,
                    'categoryblocktype_' + name + '.CATEGORY.slug'
                  )
                }
              }"
              :linkLabel="
                $ebsn.meta(config, 'categoryblocktype_' + name + '.BTN_TEXT')
              "
              :backgroundColor="
                $ebsn.meta(
                  config,
                  'categoryblocktype_' + name + '.CARD_BG_COLOR'
                )
              "
            />
          </swiper-slide>
          <swiper-slide v-for="product in products" :key="product.productId">
            <ProductCard v-bind:product="product" />
          </swiper-slide>
        </swiper>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_' + name + '.SHOW_BULLETS',
              true
            ) && products.length > 1
          "
          :id="`product-list-pagination-${config.categoryBlockId}`"
          :class="
            `swiper-pagination product-list-pagination-${config.categoryBlockId}`
          "
        ></div>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_' + name + '.SHOW_ARROWS',
              false
            ) && products.length > 1
          "
          :id="`product-list-slider-prev-${config.categoryBlockId}`"
          class="swiper-button-prev"
        ></div>
        <div
          v-if="
            $ebsn.meta(
              config,
              'categoryblocktype_' + name + '.SHOW_ARROWS',
              false
            ) && products.length > 1
          "
          :id="`product-list-slider-next-${config.categoryBlockId}`"
          class="swiper-button-next"
        ></div>
      </div>
    </v-container>
  </div>
</template>
<style global lang="scss">
.product-list-slider {
  .product-slider-card {
    width: 260px;
    height: 440px;
    flex-shrink: initial;
  }
  .swiper-slide {
    flex-shrink: 1;
  }
  .product-card {
    width: 276px;
  }
}
.show-more {
  .v-btn {
    margin-right: -4px;
  }
}
</style>
<script>
import ProductCard from "@/components/product/ProductCard.vue";
import ProductSliderCard from "./ProductSliderCard.vue";

import categoryBlockType from "@/components/categoryBlock/categoryBlockType";
import analyticsService from "~/service/analyticsService";

import Vue from "vue";

export default {
  name: "ProductListSlider",
  props: {
    config: { type: Object, required: true },
    container: { type: Boolean, default: true },
    products: { type: Array, default: () => [] },
    categoryBlockName: { type: String, required: true },
    showMore: { type: Boolean, default: false }
  },
  components: { ProductCard, ProductSliderCard },
  mixins: [categoryBlockType],
  data() {
    return {
      name: this.categoryBlockName,
      swiperDefaultOption: {
        slidesPerGroup: 1,
        slidesPerView: "auto",
        spaceBetween: 20,
        watchOverflow: true,
        autoplay: false,
        pagination: {
          el: `.product-list-pagination-${this.config.categoryBlockId}`,
          clickable: true
        },
        navigation: {
          prevEl: `#product-list-slider-prev-${this.config.categoryBlockId}`,
          nextEl: `#product-list-slider-next-${this.config.categoryBlockId}`
        }
      }
    };
  },
  computed: {
    showCard() {
      return Vue.$ebsn.meta(
        this.config,
        `categoryblocktype_${this.name}.SHOW_CARD`,
        false
      );
    },
    titleColor() {
      return this.$ebsn.meta(
        this.config,
        `categoryblocktype_${this.name}.TITLE_COLOR`
      );
    },
    hasFirstCardSlot() {
      return !!this.$slots.firstCard;
    }
  },
  methods: {
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting && this.proposal?.length > 0) {
        analyticsService.viewPromotions(
          [this.proposal],
          this.position,
          this.products
        );
      }
    },
    handleClick() {}
  }
};
</script>
