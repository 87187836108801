global.config = {
  google_api_key: process.env.VUE_APP_GOOGLE_API_KEY,
  googleGeocodeParams: {
    components: "country:it|administrative_area:Lombardia"
  },
  baseUrl: "",
  proximityDefaultDistance: "10000.0",
  showSectors: false,
  hashNotReload: ["/cart/", "/payment"],
  filterEmptyCategory: true,
  dayWordsSaved: 30,
  version: process.env.VUE_APP_VERSION,
  minProfileLevel: 1,
  registration: true,
  checkVersionInterval: 1000 * 60 * 10, //ogni 10 minuti
  reloadAfterMillis: 1000 * 60 * 30, //dopo 30 minuti di inattività restarta la app
  domainWhitelist: [
    "basko.it",
    "test.basko.it",
    "www.basko.it",
    "test-basko.digitelematica.org",
    "dev-basko.digitelematica.org",
    "localhost"
  ],
  stagingUrls: [
    "https://test.basko.it",
    "https://dev-basko.digitelematica.org",
    "http://localhost:3000"
  ],
  showInPopupCode: ["msg.cart.availability.errors"],
  dialogs: [],
  pushNotificationEnabled: true,
  urlScheme: "ebsnbasko://",
  deeplinks: {
    "/profile/orders": "/profile/orders",
    "/profile/orders/:orderId": "/profile/orders/:orderId",
    "/checkout": "/checkout",
    "/search": "/search",
    "/product/:slug": "/product/:slug",
    "/category/*": "/category/*",
    "/page/:slug": "/page/:slug",
    "/recover-password-confirm": "/recover-password-confirm",
    "/reset-password-request": "/reset-password-request",
    "/reset-password-sent": "/reset-password-sent",
    "/reset-password": "/reset-password",
    "/reset-password-completed": "/reset-password-completed"
  },
  // filterProductClasses: [1, 2, 3, 4, 6],
  updateCart: false,
  dialogMaxWidth: 530,
  addToFavoritesWidth: 350,
  analyticsClass: "analyticsServiceGTM",
  cartItemInfos: ["size", "custom_weight"],
  acceptAlternatives: true,
  categoryTreeDepth: 3,
  facebookClientId: "xxx",
  googleClientId: "xxx",
  defaultPaymentTypeId: 12,
  addAuthPaymentTypeIds: [12],
  addAuthPaymentTypePrebilledAmount: { 12: 1.01, 22: 0 },
  socialLogo: "/logo/social.png",
  defaultStoreName: "Catalogo Globale",
  defaultpostName: "Basko",
  defaultPageName: "Basko",
  recaptchaTokenEnabled: true,
  recaptchaEnabledEndpoints: [
    // "/ebsn/api/registration/user_detail",
    "/ebsn/api/registration/update_user_detail",
    "/ebsn/api/auth/login",
    "/ebsn/api/checkout/buy",
    "/ebsn/api/registration/password_reset",
    "/ebsn/api/prima-card/register-user",
    "/ebsn/api/registration/update_password"
  ],
  categoryPrefix: "/"
};
