import Vue from "vue";
import VueI18n from "vue-i18n";
import axios from "axios";
import LangService from "@/commons/service/langService";

Vue.use(VueI18n);

const loadedLanguages = [];
const messages = {};

const i18n = new VueI18n({
  locale: "it",
  fallbackLocale: "it",
  messages: messages,
  numberFormats: loadNumberFormats()
});

export default i18n;
export async function setLocale(lang) {
  let res = null;
  var forceLoadLocal = process.env.VUE_APP_I18N_FORCE_LOAD_LOCAL;

  if (!forceLoadLocal) {
    forceLoadLocal = false;
  }

  if (!loadedLanguages.includes(lang)) {
    if (!forceLoadLocal) {
      res = await loadLocaleMessages(lang);
    }
    if (!res) {
      loadLocaleMessageFromLocal(lang);
    }
    setI18nLanguage(lang);
  }
}

// init
// setLocale("it");

function setI18nLanguage(lang) {
  i18n.locale = lang;
  axios.defaults.headers.common["Accept-Language"] = lang;
  document.querySelector("html").setAttribute("lang", lang);
  return lang;
}

async function loadLocaleMessages(lang) {
  if (!lang) {
    lang = "it";
  }
  let response = await LangService.getLang("labels", 1000);
  if (response) {
    loadedLanguages.push(lang);
    messages[lang] = response;

    return messages;
  }
  return null;
  // }
}

function loadLocaleMessageFromLocal() {
  const locales = require.context(
    "@/localization",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      loadedLanguages.push(locale);
      messages[locale] = locales(key);
    }
  });
  return messages;
}

function loadNumberFormats() {
  const numberFormats = {
    "en-US": {
      currency: {
        style: "currency",
        currency: "USD"
      }
    },
    it: {
      currency: {
        style: "currency",
        currency: "EUR",
        currencyDisplay: "symbol"
      }
    }
  };
  return numberFormats;
}
